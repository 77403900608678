// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game {
    height: 100vh;
    display: flex;
    width: 100%;
}

.game__size {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.game__pic__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; 
}

.game__pic__container > img {
    width: 550px;
    display: block; 
}

.game__pics {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.game__pics > img {
    width: 250px;
}

.game__button {
    display: flex;
    align-items: end;
    justify-content: center;
}

.game__button > button {
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/game/Game.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".game {\n    height: 100vh;\n    display: flex;\n    width: 100%;\n}\n\n.game__size {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.game__pic__container {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1000; \n}\n\n.game__pic__container > img {\n    width: 550px;\n    display: block; \n}\n\n.game__pics {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.game__pics > img {\n    width: 250px;\n}\n\n.game__button {\n    display: flex;\n    align-items: end;\n    justify-content: center;\n}\n\n.game__button > button {\n    margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
